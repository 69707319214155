import React from "react";
import './index.less'

export interface ZSpinProps {

}

const ZSpin = (props: ZSpinProps) => {

    return (
        <div className="zc-spin zc-spin-lg zc-spin-spinning">
            <div className="zc-spin-dot zc-spin-dot-spin">
                <i className="zc-spin-dot-item"/>
                <i className="zc-spin-dot-item"/>
                <i className="zc-spin-dot-item"/>
                <i className="zc-spin-dot-item"/>
            </div>
        </div>
    )
}
export default ZSpin