import React from "react";
import {Api} from "@/api";

interface Props {
    row: any
}

const OrderPrint = (props: Props) => {
    const {row} = props

    // 打印箱唛
    const onPrint = () => {
        Api.Order.GetOrderLabel({
            orderNo: row.no,
            companyId: row.supplyCode,
            shipmentId: row.shipmentId,
            orderId: row.id
        }).then((res: any) => {
            if (res.data) {
                window.open(res.data, '_blank')
            }
        })
    }

    return <a style={{color: '#999999'}} className='order-op-item' onClick={onPrint}>打印箱唛</a>
}

export default OrderPrint