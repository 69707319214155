import React from "react";
import {Api} from "@/api";
import {getFields} from "@/pages/createOrder/interface";
import {object2Query} from "@/utils/ZUtils";

interface Props {
    row: any
}

const OrderOneMore = (props: Props) => {
    const {row} = props

    const toOneMore = () => {
        sessionStorage.removeItem('fields')
        sessionStorage.removeItem('topAddr')
        sessionStorage.removeItem('boxPics')
        Api.Order.GetOrderDetail({orderId: row?.id}).then((res: any) => {
            const {templateName, templateId, method, topSender, country, wareCode} = res?.data
            const boxDtoList = res?.data?.boxDtoList.reduce((result: any, item: any) => {
                const {high, id, feeWeight, long, num, weight, wide, productDtos} = item
                result.push(
                    {
                        high,
                        id,
                        feeWeight,
                        long,
                        num,
                        weight,
                        wide,
                        productDtos
                    }
                )
                return result
            }, [])
            const json = {
                ...getFields(boxDtoList),
                deliverType: res.data.deliverType,
                shipmentId: '',
                referenceId: '',
                wareCode: `${country}||${wareCode}`,
                ...res.data.topSender,
                areaCode: res.data.topSender.code,
                topAddr: {},
                addr: '{}',
                templateId,
                method,
                templateName
            }
            sessionStorage.setItem('fields', JSON.stringify(json))
            location.href = `/order/createorder.html${object2Query({
                TemplateName: templateName.replace('%', '%25'),
                TemplateId: templateId,
                Method: method,
                country,
                ware: `${country}||${wareCode}`,
                from: topSender.code
            })}`
        })
    }

    return <a className='mb10 order-op-item color-2c6ecb' onClick={toOneMore}>再来一单</a>
}
export default OrderOneMore
