import React, {useEffect, useState} from "react";
import {Api} from "@/api";
import ZTimeline from "@/components/Timeline";
import ZSpin from "@/components/Spin";
import Modal from "@/components/Modal";

interface Props {
    item: any
}

const cReg = /火火/ig

const TrackInfo = (props: Props) => {
    const [data, setData] = useState<any>([])
    const {item} = props
    const [loading, setLoading] = useState<boolean>(false)
    const [visible, setVisible] = useState<boolean>(false)
    const [url, setUrl] = useState<string>('')
    useEffect(() => {
        if (visible) {
            setLoading(true)
            setData([])
            if (item?.id) {
                Api.Order.GetTraceDetail({
                    regist17:item.regist17,
                    orderId: item.id,
                    traceNo: item?.traces?.[0],
                    track17Key: item.track17Key,
                    traceName: item.traceBy,
                    companyId: item.supplyCode || 0
                }).then((res: any) => {
                    const trackDatas = res.data?.trackDatas?.reverse() ?? []
                    setUrl(trackDatas[0]?.attachment || '')
                    setData(trackDatas)
                    setLoading(false)
                }).catch(() => {
                    setLoading(false)
                })
            }
        }

    }, [visible])
    const onClick = () => {
        if (!item?.traces?.[0] && item.shipTrace) {
            window.open(`https://marineonline.com/tools/vessel-finder/details/${item?.shipId}`, '_blank')
        }
        if (item?.traces?.[0]) {
            setVisible(true)
        }
    }
    // title
    const titleRender = () => {
        return (
            <div className='flex align-items-center'>
                <div className='mr30'>追踪详情</div>
                {url && <a className='f14 color-2c6ecb' href={url} target='_blank'>查看POD证明</a> || ''}
            </div>
        )
    }

    return (
        <>
            <a onClick={onClick} style={{color: '#0074ff'}}>{item?.traces?.[0] ?? item?.shipTrace}</a>
            <Modal visible={visible} title={titleRender()} width={1000} onCancel={() => setVisible(false)}
                   footer={false}
                   maxHeight={700}>
                <div style={{minHeight: '300px'}}>
                    <ZTimeline>
                        {data?.length && data?.map((i: any, index: number) =>
                            <ZTimeline.Item key={index} line={index !== data.length - 1}
                                            color={index === 0 && '#1890ff' || '#666666'}>
                                <span>{i.date}</span>&emsp;
                                <span>{i.location?.replace(cReg, '头程宝')}</span>&emsp;
                                <span>{i.description}</span>
                            </ZTimeline.Item>
                        ) || ''}

                        {
                            loading
                            &&
                            <div style={{height: '200px'}} className='flex align-items-center justify-center'>
                                <ZSpin/>
                            </div>
                            || ''
                        }
                    </ZTimeline>
                </div>
            </Modal>
        </>

    )
}
export default TrackInfo