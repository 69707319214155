import React, {useState} from "react";
import Modal from "@/components/Modal";

interface Props {

}

const Compensation = (props: Props) => {
    const [visible, setVisible] = useState<boolean>(false)

    return (
        <>
            <a style={{color: '#007aff'}} onClick={() => setVisible(true)}>赔付标准</a>
            <Modal visible={visible} width={900} onCancel={() => setVisible(false)} footer={false} title='头程宝赔付标准'>
                <div className='bolder color-333 mb20 f15'>
                    我们希望在FBA跨境物流行业有一个可以更加明确的赔付标准，我们认为：以亚马逊后台的上架信息和国际4大快递的官网签收证明，是更加明确的标准。所以我们的赔付标准如下：
                </div>
                <div className='mb20'>
                    1、UPS/联邦快递/DHL/DPD没有签收证明或者卡派没有POD签收证明文件，且产品最终在亚马逊没有上架，赔付产品采购货值+对应物流费之和的50%，单票最高赔付1000美金。
                </div>
                <div className='mb20'>
                    2、有UPS/联邦快递/DHL/DPD签收证明，但是货物没有上架，协助客户提供签收证明找亚马逊索赔，同时我们会找保险公司索赔，如果亚马逊没有赔付，保险公司最终也没有赔付（对应的箱单信息必须和亚马逊货件一致），由头程宝补偿客户：产品采购货值+对应物流费之和的20%，单票最高200美金。
                </div>
                <div className='mb20'>
                    3、产品标签和外箱标签是由头程宝粘贴，且物流发货在头程宝下单发货，且亚马逊未上架的产品数量超过3%，赔付对应未上架产品货值+对应物流费的50%，单票最高赔付1000美金。备注：由客户本身发错标签的不赔付。
                </div>
            </Modal>
        </>
    )
}

export default Compensation