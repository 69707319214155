import React, {useEffect, useState} from "react";
import ZTable from "@/components/Table";
import {getCity, getOrderStat, IOrderItem, logisticsMap, orderStatMap, payStatMap} from "@/pages/order/interface";
import {IColumn} from "@/components/Table/interface";
import OrderOneMore from "@/pages/order/components/oneMore";
import OrderPrint from "@/pages/order/components/print";
import {Api} from "@/api";
import TrackInfo from "@/pages/order/components/trackInfo";
import Compensation from "@/pages/components/compensation";

const orderClassStat = {
    10: 'order-unpaid',
    20: 'order-payment',
    100: 'order-common'
}

const PageSize: number = 30

interface Props {
    params: any
    setOrderStat?: any
    setPaymentStat?: any
    from?: 'goods' | 'order'
}

const OrderList = (props: Props) => {
    const {params, setOrderStat, setPaymentStat, from = 'order'} = props
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [data, setData] = useState<IOrderItem[]>([]);
    const [cityMap, setCityMap] = useState()

    useEffect(() => {
        Api.Division.GetDivisions().then((res: any) => setCityMap(res.data))
    }, [])
    const getOrderList = () => {
        // if(from === 'goods'){
        //     Api.Order.GetListByGood()
        // }else{
        Api.Order[from === 'goods' && 'GetListByGood' || 'GetZorderList']({
            ...params,
            PageSize,
            Page: page
        }).then((res: any) => {
            if (res.state) {
                const OrderData = res.data?.pageResult;
                setData(OrderData.data);
                setTotal(OrderData.total);
                setOrderStat?.(getOrderStat(res.data?.orderStateCounts, 1))
                setPaymentStat?.(getOrderStat(res.data?.payStateCounts, 2))
            }
        })
        // }

    }
    useEffect(() => {
        getOrderList();
    }, [params, page]);

    // 查看订单详情
    const toOrderDetail = (r: any) => {
        sessionStorage.removeItem('order')
        location.href = `/order/CheckOrder.html?id=${r.id}`
    }

    // 时间 编号
    const noRender = (t: string, row: any) => (
        <>
            <div className='mb10'>{row.no}</div>
            <div className='color-999'> {t?.replace('T', " ")?.substring(2, 16) ?? ''} </div>
        </>
    )
    // 路线信息
    const wareRender = (text: string, record: any) => (
        <>
            <div className='mb10 flex align-items-center order-route'>
                {getCity({pid: record.topSender.code, type: 1, source: cityMap})}
                <div className="zc-arrow" style={{marginTop: record.country && '-10px' || '1px'}}>{record.country}</div>
                <span className='bolder'>{text}</span>
            </div>
            <div className='color-999 f13'>[{logisticsMap[record?.method]}] {record?.templateName} </div>
            <div className='mt10'><span className='color-999'>{record?.days}</span></div>
        </>
    )
    //货件编号 & 追踪编码
    const shipmentRender = (text: string, row: any,record: any) => (
        <div>
            <div className='mb10'>货件编号: {text}</div>
            <div>
                <span className='color-999'>追踪号：</span>
                <TrackInfo item={row}/>
            </div>
            <div className='mt10'><span className='color-999'>承运商: {record.traceBy}</span></div>
        </div>
    )
    // 货物信息
    const infoRender = (text: number, record: any) => (
        <div>
            <div className='ovh mb10 pointer'
                 title={record.goodsDetail}> {record.goodsNames?.substring(0, 20)} </div>
            <div className='color-999'> {text || record?.feeWeight} kg 共 {record?.box} 箱</div>
            <div className='mt10'><span className='color-999'>共 {record?.quantity} 件</span></div>
        </div>
    )
    // 物流状态
    const stateRender = (t: number) =>
        <div>
            <div className='mb10'>{orderStatMap[t]}</div>
            <Compensation/>
        </div>
    //订单费用
    const costRender = (text: number, record: any) => (
        <div className='text-right'>
            <div className={`${orderClassStat[record.paymentState]} f12 mb10`}>{payStatMap[record.paymentState]}</div>
            <div className='mb10'>￥{record.feeUser || text || record.preShip}</div>
            {record?.insureUrl && <a href={record?.insureUrl} style={{color: '#007aff'}}>电子保单</a> || ''}
        </div>
    )
    // 操作
    const opRender = (_: any, row: any) => (
        <div className='flex flex-column'>
            <a style={{color: '#007aff'}} className='mb10 order-op-item' onClick={() => toOrderDetail(row)}>订单详情</a>
            <OrderOneMore row={row}/>
            {row.otherId && <OrderPrint row={row}/> || ''}
        </div>
    )

    const column: IColumn[] = [
        {title: '订单编号 & 时间', width: 140, key: 'ptime', render: noRender},
        {title: '路线信息', key: 'wareCode', render: wareRender},
        {title: '货件编号 & 追踪编码', key: 'shipmentId', render: shipmentRender},
        {title: '货物信息', key: 'lastFeeKg', render: infoRender},
        {title: '物流状态', key: 'state', width: 90, render: stateRender},
        {title: <div className='text-right'>订单费用</div>, key: 'ship', width: 90, render: costRender},
        {title: '操作', width: 90, render: opRender}
    ]

    return (
        <ZTable
            vertical='baseline'
            onChange={setPage}
            pagination
            total={total}
            data={data}
            column={column}
            pageSize={PageSize}
        />
    )
}
export default OrderList